<template>
	<div>
    <!-- <Loader v-if="!usersTotal || (usersTotal && usersTotal.length == 0)" /> -->
    <div>
      <div class="dashboard__container--body pt-5">
      	<!-- <div class="dashboard__container--body--col">
          <div class="shiftCard">
            <div>Total Users: {{usersTotal}}</div>
            <div>Without Address: {{usersWithoutAddress.length}} - 
              <button class="btn btn__small btn__dark mr-4" @click="exportReport()" v-if="usersWithoutAddress && usersWithoutAddress.length >= 1">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
          </div>
        </div> -->
        <div class="dashboard__container--body--col">
          <div>
          <h4>User Growth - Last 45 Days</h4>
          <graph-component :dailyUserData="dailyUserData" />
          </div>
        </div>
      
        <div class="dashboard__container--body--col">
          <div>
            <h4 class="mb-3">Login Activity</h4>
            <p>Total logins in the last 24 hours: {{ totalLoginsLast24Hours }}</p>
            <p>Total logins in the last 7 days: {{ totalLoginsLast7Days }}</p>
            <p>Total logins in the last 30 days: {{ totalLoginsLast30Days }}</p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import ExportService from "@/services/ExportService"
import * as moment from 'moment'
// import { Bar, mixins } from 'vue-chartjs';
import GraphComponent from '@/components/GraphComponent.vue'; 

export default {
  name: 'userParse',
  components: {
    Loader,
    GraphComponent
  },
  data() {
    return {
      performingRequest2: false,
      dailyUserData2: [
        {
          date: "December 28, 2023 at 4:00:00 PM UTC-8",
          totalUsersCount: 8569
        }
      ]
    }
  },
  // created () {
    
  // },
  mounted() {
    this.$store.dispatch("getAllUserLogins", { pageSize: 1000 });
    this.$store.dispatch("getDailyUserData");
  },
  computed: {
    ...mapState(['usersTotal', 'usersWithoutAddress', 'allUserLogins', 'dailyUserData']),
    // totalLoginsLast24Hours() {
    //   if (this.allUserLogins && this.allUserLogins.length > 0) {
    //     const currentTime = moment();
    //     return this.allUserLogins.filter((timestamp) => {
    //       return currentTime.diff(moment.unix(timestamp.created), "hours") <= 24;
    //     }).length;
    //   }
    //   return 0;
    // },
    totalLoginsLast24Hours() {
      if (this.allUserLogins && this.allUserLogins.length > 0) {
        const currentTime = moment();
        const twentyFourHoursAgo = moment().subtract(24, 'hours');

        return this.allUserLogins.filter((timestamp) => {
          const loginTime = moment.unix(timestamp.created.seconds);
          return loginTime.isBetween(twentyFourHoursAgo, currentTime, null, '[]'); // Check if loginTime is within the 7-day window
        }).length;
      }
      return 0;
    },
    // totalLoginsLast7Days() {
    //   if (this.allUserLogins && this.allUserLogins.length > 0) {
    //     const currentTime = moment();
    //     return this.allUserLogins.filter((timestamp) => {
    //       return currentTime.diff(moment.unix(timestamp.created), "days") <= 7;
    //     }).length;
    //   }
    //   return 0;
    // },
    totalLoginsLast7Days() {
      if (this.allUserLogins && this.allUserLogins.length > 0) {
        const currentTime = moment();
        const sevenDaysAgo = moment().subtract(7, 'days');

        return this.allUserLogins.filter((timestamp) => {
          const loginTime = moment.unix(timestamp.created.seconds);
          const loginDate = loginTime.toDate(); // Convert Firestore Timestamp to JavaScript Date

          return loginTime.isBetween(sevenDaysAgo, currentTime, null, '[]');
        }).length;
      }
      return 0;
    },
    totalLoginsLast30Days() {
      if (this.allUserLogins && this.allUserLogins.length > 0) {
        const currentTime = moment();
        const thirtyDaysAgo = moment().subtract(30, 'days');

        return this.allUserLogins.filter((timestamp) => {
          const loginTime = moment.unix(timestamp.created.seconds);
          const loginDate = loginTime.toDate(); // Convert Firestore Timestamp to JavaScript Date

          return loginTime.isBetween(thirtyDaysAgo, currentTime, null, '[]');
        }).length;
      }
      return 0;
    },
    // totalLoginsLast7Days() {
    //   return this.calculateTotalLogins(7 * 24 * 60 * 60 * 1000);
    // },
    // totalLoginsLast30Days() {
    //   return this.calculateTotalLogins(30 * 24 * 60 * 60 * 1000);
    // },
  },
  
  methods: {
    calculateTotalLogins(timeInterval) {
      const now = Date.now();
      const filteredLogins = this.allUserLogins.filter(
        (timestamp) => now - timestamp < timeInterval
      );
      return filteredLogins.length;
    },
    exportReport() {
      this.performingRequest2 = true
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
      ];
      const exportItems = [];
      for (var key in this.usersWithoutAddress) {

        exportItems.push([
          this.usersWithoutAddress[key].firstName,
          this.usersWithoutAddress[key].lastName,
          this.usersWithoutAddress[key].email,
          this.usersWithoutAddress[key].phone,
        ]);
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
      setTimeout(() => {
        this.performingRequest2 = false
      }, 2000)
    },
  },
  beforeDestroy () {
    // this.$store.dispatch("clearUsersTotal");
    // this.$store.dispatch("clearUsersWithoutAddress");
    this.$store.dispatch("clearAllUserLogins");
    this.$store.dispatch("clearDailyUserData");
  },
}
    
</script>