<template>
  <div class="user-growth-graph">
    <div class="y-axis">
      <!-- User count labels on the Y-axis -->
      <!-- <div v-for="label in yAxisLabels" :key="label" class="y-axis-label">{{ label }}</div> -->
    </div>
    <div class="graph">
      <!-- Data points with date labels on the X-axis -->
      
      <div
        v-for="(dataPoint, index) in dailyUserData"
        :key="index"
        class="data-point"
        :style="{ height: `calc(${dataPoint.totalUsersCount * 0.008}%)` }"
      >
      <v-popover>
          <span class="full"></span>
          <template slot="popover">
            <div>
              {{ formatStamp(dataPoint.date) }} - {{ dataPoint.totalUsersCount }} Users
            </div>
          </template>
          
        </v-popover>
        <!-- <div class="x-axis-label">{{ dataPoint.date }}</div> -->
        <!-- <div class="y-axis-label">
          {{ dataPoint.totalUsersCount }} Users
        </div> -->
        <!-- <div v-for="label in yAxisLabels" :key="label" class="y-axis-label">{{ label }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'

export default {
  props: ['dailyUserData'],
  // data() {
  //   return {
  //     dummyData: [
  //       { date: '2022-01-01', totalUsers: 100 },
  //       { date: '2022-01-02', totalUsers: 120 },
  //       { date: '2022-01-03', totalUsers: 140 },
  //       { date: '2022-01-04', totalUsers: 160 },
  //       { date: '2022-01-05', totalUsers: 180 },
  //       { date: '2022-01-06', totalUsers: 200 },
  //       // Add more data points as needed
  //     ],
  //   };
  // },
  computed: {
    yAxisLabels() {
      // Generate Y-axis labels based on user count data
      const maxUsers = Math.max(...this.dailyUserData.map(dataPoint => dataPoint.totalUsersCount * 2 ));
      const step = Math.ceil(maxUsers / 5); // Adjust the number of steps as needed
      const labels = [];
      for (let i = maxUsers; i >= 0; i -= step) {
        labels.push(i);
      }
      labels.push(0);
      return labels;
    },
  },
  methods: {
    formatStamp(stamp) {
      if (stamp) {
        const postedDate = new Date(stamp.seconds * 1000);
        return moment(postedDate).format('MMMM DD, YYYY')
      }
    },
  }
};
</script>

<style scoped>
  .full {
    height: 100%;
    width: 10px;
    position: absolute;
    background-color: #00C897;
    top: 0;
    border-radius: 10px 10px 0 0;
  }
  .full:hover {
    background-color: #1F966F;
  }
.user-growth-graph {
  width: 100%;
  height: 240px;
  position: relative;
/*   border: 1px solid #ccc; */
  overflow: hidden;
  padding: 0 !important;
}

.graph {
/*   width: calc(100% - 48px); */
width: 100%;
/*   height: calc(100% - 24px); */
height: 100%;
  position: absolute;
  top: 0; /* Adjusted to be at the bottom */
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
/*   padding: 0 20px; */
}

.data-point {
  width: 10px;
  background-color: transparent;
  position: relative;
  border-radius: 10px 10px 0 0;
}

.x-axis-label {
  position: absolute;
  bottom: -20px; /* Adjust the distance from the data point */
  left: -10px; /* Adjust the horizontal position of the label */
  font-size: 12px;
  white-space: nowrap;
}

.y-axis {
  width: 48px;
/*   height: calc(100% - 24px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  bottom: 0;
  left: 0;

}

.y-axis-label {
/*   margin-bottom: 24px; */
/*    margin-top: -36px; */
}
</style>

